import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

const SourceDef = {
  alipay: "支付宝",
  bank: "对公转账",
  "finance-analysis": "财务审查",
  "ip-analysis": "专利评估",
  system: "系统",
};

export interface AccountRecord {
  balance_change: number;
  type: string;
  source: string;
  ts: Date;
  source_no: string;
}

export interface Order {
  id: string;
  created_ts: string;
  completion_ts: string;
  item_count: number;
  freeze_dots: number;
  used_dots: number;
  status_code: string;
  type: string;
}

export interface Refund {
  amount: number;
  channel: string;
  completionTS: number;
  dots: number;
  outSerialNo: string;
}

export interface Invoice {
  amount: number;
  channel: string;
  completionTS: string;
  dots: number;
  outSerialNo: string;
}

export interface UserAccountInfo {
  accountChangeRecords: Array<AccountRecord>;
  orderList: Array<Order>;
  refundRecords: Array<Refund>;
  invoiceRecords: Array<Invoice>;
}

@Module
export default class AccountModule
  extends VuexModule
  implements UserAccountInfo
{
  accountChangeRecords: Array<AccountRecord> = [];
  orderList: Array<Order> = [];
  refundRecords = [];
  invoiceRecords = [];

  /**
   * Get current user object
   * @returns User
   */
  get accountRecords() {
    return this.accountChangeRecords;
  }

  get depositRecords() {
    return this.accountChangeRecords.filter((r) => {
      return r.type === "deposit";
    });
  }

  get orders() {
    return this.orderList;
  }

  get pendingFAOrders() {
    return this.orderList.filter((o) => {
      return o.type === "finance-analysis" && o.status_code === "10200";
    });
  }

  get pendingIPOrders() {
    return this.orderList.filter((o) => {
      return o.type === "ip-analysis" && o.status_code === "10200";
    });
  }

  get refunds() {
    return this.refundRecords;
  }

  get invoices() {
    return this.invoiceRecords;
  }

  @Mutation
  [Mutations.SET_ACCOUNT_RECORDS](records) {
    records.forEach((r: AccountRecord) => {
      r.source = SourceDef[r.source];
      r.ts = new Date(r.ts);
    });
    records.sort((a, b) => {
      return a.ts > b.ts ? -1 : 1;
    });
    this.accountChangeRecords = records;
  }

  @Mutation
  [Mutations.ADD_ORDER](order) {
    this.orderList.unshift(order);
  }

  @Mutation
  [Mutations.SET_ORDERS](orders) {
    this.orderList = orders;
  }

  @Mutation
  [Mutations.UPDATE_ORDER](order) {
    const index = this.orderList.findIndex((o) => {
      o.id = order.id;
    });
    this.orderList.splice(index, 1);
    this.orderList.splice(index, 0, order);
  }

  @Action
  [Actions.INVOKE_ALIPAY](payload) {
    return ApiService.post(process.env.VUE_APP_ALIPAY_GATEWAY!, payload);
  }

  @Action
  [Actions.GET_ACCOUNT_RECORDS]() {
    return ApiService.get("account/record").then(({ data }) => {
      this.context.commit(Mutations.SET_ACCOUNT_RECORDS, data);
    });
  }

  @Action
  [Actions.GET_ORDERS]() {
    return ApiService.get("order").then(({ data }) => {
      this.context.commit(Mutations.SET_ORDERS, data);
    });
  }
}
