<template>
  <transition name="agreement-slide">
    <div
      class="modal bg-white fade shadow-none"
      tabindex="-1"
      id="modal_agreement"
    >
      <div class="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <h4 class="modal-title">请认真阅读以下条款</h4>
          </div>

          <div class="modal-body px-20">
            <div id="agreement-content">
              <h3 class="text-center">“先识”尽调辅助审查服务协议</h3>
              <br />
              <p>
                <strong
                  >《“先识”尽调辅助审查服务协议》（以下亦称“本协议”）由您（以下亦称“用户”或“服务用户”）和南京青春信息科技有限公司（以下简称“本公司”）进行签署，对双方具有约束力。</strong
                >
              </p>
              <p>
                <strong>
                  请您仔细阅读本协议的全部内容，尤其是以加粗形式展示的条款，可能与您的权益存在重大关系（包括相关责任、您享有的权利、争议解决条款），请您留意重点阅读。若您认为本协议中的加粗条款可能会导致您的部分或全部权利或利益受损，请您务必权衡自身实际情况，在确保您已经理解、接受了加粗条款的前提下，开通或使用本软件服务。
                </strong>
              </p>
              <p>
                <strong>
                  如果您不同意本协议的任一或全部条款内容，请不要以确认形式（包括但不限于支付/购买/接受赠与、或完成了成为用户的全部程序而在此过程中未向本公司提出关于本协议的任何异议、或使用用户服务）进行下一步操作或使用本公司服务。当您以确认形式进行下一步操作时，即表示您与本公司已达成协议关系，您自愿接受本协议并遵守本协议项下的全部约定。
                </strong>
              </p>
              <p>
                <strong>
                  本公司有权变更本协议内容，一旦本协议内容发生变更的，本公司将在相应页面、或以其他合理方式进行通知，请您仔细阅读。如果您不同意变更的内容的，您可以选择停止使用本公司服务。如您继续使用的，则视为您已经同意变更的全部内容。更新后的协议自文首更新之日起生效。
                </strong>
              </p>
              <p><strong>本《用户服务协议》将帮助您了解以下内容：</strong></p>
              <p><strong>第一条、名词定义</strong></p>
              <p>
                1. 本协议
                &ldquo;青春科技&rdquo;&ldquo;我们&rdquo;&ldquo;本公司&rdquo;是指南京青春信息科技有限公司。
              </p>
              <p>
                2.
                本协议所称&ldquo;<strong>平台</strong>&rdquo;指以本公司主体建设、运营的网站、APP及小程序，包括但不限于：域名（www.forecog.com）及/或其他任何由我们直接所有或运营的任何网页平台及软硬件客户端平台，适用终端包括但不限于PC、平板、手机、可穿戴设备等。
              </p>
              <p>
                3.
                本协议所称&ldquo;<strong>用户</strong>&rdquo;需具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或非法人组织，包括注册用户及未注册用户。凡未注册使用我们的产品及/或服务的用户，自开始使用我们的产品及/或服务时即成为我们的&ldquo;非注册用户&rdquo;，在使用过程中须遵循本协议中除注册用户专属约定以外的其他所有条款。
              </p>
              <p>
                4.
                本协议所称&ldquo;<strong>服务</strong>&rdquo;适用于青春科技所有服务，包括但不限于法律咨询；案件分析；法律法规查询、下载；法律文书查询、下载；合同起草、审核、修改等。根据不同的产品内容，对前述服务种类进行组合。
              </p>
              <p>
                5.
                本协议所指&ldquo;<strong>账号</strong>&rdquo;指您在平台上注册的用户名称，用于登录平台查看个人、订单信息及查看用户权益。您可以直接在我们平台使用手机号注册，亦可通过网站所支持的第三方账号（如微信登录）授权登录方式登录。在成功注册账号后，您亦可通过授权登录等方式使用青春科技账号登录其他青春科技名下软件，如微信小程序、APP等。
              </p>
              <p><br /></p>
              <p>
                <strong
                  >第二条<span style="white-space: pre">、</span
                  >平台账号获取及使用</strong
                >
              </p>
              <p>
                2.1
                为保证您顺利使用我们的产品及服务，在注册使用账号登录、享受本平台服务时（包括通过第三方账号授权登录）应遵守以下规则：
              </p>
              <p>
                1)<span style="white-space: pre"> </span
                ><strong
                  >您在注册青春科技账号（包括您通过第三方账号授权登录）登录平台时，应根据页面提示向我们提供完整、真实、准确、最新的必要注册信息，不得使用他人邮箱、手机号码或以其他冒用他人身份的方式进行账号注册。</strong
                >在提交注册信息后，如果上述信息发生变化，您应及时更改。且您提交的名称、头像和简介等注册信息中不得出现违法和不良信息。
              </p>
              <p>
                2)<span style="white-space: pre"> </span
                >因为提供服务的需要，必要时您应当应当提供真实、完整、准确的认证信息：个人用户应当提供身份证号码、真实姓名、联系电话、地址、邮政编码等；法人及非法人组织<strong>进行主体账户认证的，应当提供真实且加盖公章的营业执照扫描件及统一社会信用代码。</strong>（亦可以线下方式签订法律服务合同）
              </p>
              <p>
                3)<span style="white-space: pre"> </span
                >除通过第三方账号授权登录外，您仅可通过我们的官方网站渠道注册成为我们的注册用户。
              </p>
              <p>
                2.2
                <strong
                  >您享有青春科技账号的有限使用权，您的账号仅可为个人出于非商业目的进行使用，</strong
                >不得以转让、出租、出借、售卖、分享或其他任何方式交由他人使用（但法律另有规定或双方另有明确约定的除外）。
              </p>
              <p>2.3 账号管理及安全</p>
              <p>
                2.3.1
                您应自行负责并妥善、正确地保管、使用、维护您的账号和密码，并对您的账号和密码采取必要和有效的保密措施。非因我们法定过错导致的任何遗失、泄露、被篡改、被盗以及其他因保管、使用、维护不当而造成的损失，应当由您自行承担；
              </p>
              <p>
                2.3.2
                如果您发现账号存在其他异常情况导致无法正常登录使用，您需要按照我们官方公布的账号找回流程进行账号找回。在找回过程中，我们可能会要求您提供相应信息及/或证明资料，请确保您所提供的内容真实有效，否则将可能无法通过我们的验证而导致找回失败；
              </p>
              <p>
                2.3.4
                为保护账号安全，防止账号被盗等情况发生，我们可能会不时或定期采用一种或多种方式对注册用户进行用户身份验证（如短信验证、邮件验证等），如用户未成功通过验证，我们有合理理由怀疑该账号出现被盗等不安全情况，并可能视情节严重情况而单方决定是否中止或终止向该账号继续提供我们的产品及/或服务及/或采取进一步措施。您我双方一致同意，我们采取前述措施是为了保护用户账号安全，我们无需因此承担任何责任。
              </p>
              <p>
                2.4
                若您有以下行为，我们有权暂时中止或永久终止您对账号的使用或收回您的账号，中断或终止向您继续提供我们的产品及/或服务，且无需承担任何责任：
              </p>
              <p>
                1)<span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span
                >根据您的主动申请；
              </p>
              <p>
                2)<span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span
                >您违反本协议或其他我方与您签署的协议内容的；
              </p>
              <p>
                3)<span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span
                >根据我们必须遵守的法律法规、监管政策；
              </p>
              <p>
                4)<span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span
                >我们有理由认为您的使用行为对我们及/或他人的合法权益造成（或可能造成）侵害的。
              </p>
              <p>2.5 &nbsp;账号注销&nbsp;</p>
              <p>
                我们为您提供账号注销功能，您可以通过我们平台提供的在线注销方式、或通过其他我们提供的方式注销您的账号。
              </p>
              <p><br /></p>
              <p>
                <strong
                  >第三条、<span style="white-space: pre">&nbsp;</span
                  >服务使用规则及收费</strong
                >
              </p>
              <p>3.1 服务使用的基本原则</p>
              <p>
                3.1.1
                您在使用我们的产品及/或服务的过程中，应遵守法律法规及其他规范性文件，包括但不限于《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《中华人民共和国网络安全法》、《信息网络传播权保护条例》等；应遵守公共秩序，尊重社会公德，不得危害网络安全，不得利用网络从事危害国家安全、荣誉和利益，煽动颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视，传播暴力、淫秽色情信息，编造、传播虚假信息扰乱经济秩序和社会秩序，以及侵害他人名誉、隐私、知识产权和其他合法权益等活动。<strong
                  >在任何情况下，我们一旦合理地认为您存在或可能存在上述行为的，可以在任何时候，不经事先通知中止或终止向您提供我们的产品及/或服务。</strong
                >
              </p>
              <p>
                3.1.2
                <strong
                  >除法律另有规定或双方另有约定外，我们授予您对我们的产品及/或服务一项个人的、非独家的、不可转让的、非商业用途的、可撤销的、有期限的使用许可。即：您仅可出于个人目的使用我们的产品及/或服务。</strong
                >
              </p>
              <p>
                3.1.3
                <strong
                  >您知悉并同意，受限于宽带和网络连接问题，我们在提供产品及/或服务的过程中可能会出现需较长时间缓冲或卡顿或其他类似的情况，此类情况并非我们的过错，我们无需就此类情况向用户承担责任。且为了减少甚至避免上述情况的出现，从而进一步提升用户的服务体验，我们可采取一系列技术手段对产品及/或服务进行优化。</strong
                >
              </p>
              <p>
                3.1.4
                您知悉并同意，我们提供的产品及/或服务需接入互联网方可使用，因此您需自行承担使用产品及/或服务过程中所产生的上行和下行的网络通信及流量费用，上述费用将由网络运营商收取。您亦可通过产品或终端的相关设置页面对网络使用信息进行相关设置。
              </p>
              <p>3.2 服务使用规则：</p>
              <p>具体以平台上页面的价格、服务程序说明或我们实际提供为准；</p>
              <p>3.3 服务收费</p>
              <p>
                3.3.1 服务总价= 服务单价X数量。
                您可通过线下付费方式完成服务费用的支付，如银行转账、微信支付、支付宝支付、线下支付等。本公司将会把用户账户内充入对应次数。请您在点数/次数到账后的24小时内核对，若未进行异议反馈，视为支付并充值成功。
              </p>
              <p>
                3.3.2
                若您需要相应发票，请与我方客服联系，并按照要求提供订单信息及与订单支付主体名称一致的税务体信息。
              </p>
              <p>
                <strong
                  >3.3.3
                  本公司提供的服务系网络商品和虚拟商品，采用先收费后服务的方式，费用是您所购买的服务所对应的网络商品价格，而非存款、定金、储蓄卡等性质，费用一经开通成功并计入账户内后不可转让或退款（如因服务存在重大瑕疵导致您完全无法使用等违约情形、本协议另有约定、法律法规要求必须退款的经青春科技判断后认为可以退款等除外）。
                </strong>
              </p>
              <p>
                3.3.4
                我们在此特别提醒您，您在购买服务（包括自动续费服务）之前应仔细核对账号信息、购买的服务内容、价格、服务期限、服务次数等信息。
              </p>
              <p>3.3.5 收费标准、方式的变更</p>
              <p>
                服务的收费方式、收费标准由本公司根据运营成本、市场价格、运营策略等综合考虑后独立决定（调整包括但不限于促销、涨价等），并在相关的产品服务宣传及支付页面向您展示；若您在购买和续费时，相关收费方式发生变化的，以向本公司实际支持的收费方式为准；相关服务的价格发生了调整的，应以平台上公示的现时有效的价格为准（但青春科技与您另有约定的情形除外）。您同意您继续操作的行为（包括但不限于点击同意、或继续购买、或完成支付行为、或使用网站服务、或进行额外付费等），即视为您知悉并同意变更后的收费方式、收费标准。
              </p>
              <p><br /></p>
              <p>
                <strong
                  >第四条、<span style="white-space: pre">&nbsp;</span
                  >您的权利义务</strong
                >
              </p>
              <p>
                <strong
                  >4.1
                  您应在遵守本协议、法律法规及其他规范性文件的前提下访问和使用我们的产品及/或服务。您不应进行或利用我们的产品及/或服务进行下列活动（也不为他人进行下列活动提供便利和支持）：</strong
                >
              </p>
              <p>
                <strong
                  >1)<span style="white-space: pre"> </span
                  >以任何方式危害未成年人及/或其他人；</strong
                >
              </p>
              <p>
                <strong
                  >2)<span style="white-space: pre"> </span
                  >从事侵犯我们或他人合法权益的活动或违法犯罪活动，包括为上述活动提供帮助；&nbsp;</strong
                >
              </p>
              <p>
                <strong
                  >3)<span style="white-space: pre"> </span
                  >未经我们及他人（如涉及）事先明确书面许可，自行或委托他人以任何方式（包括但不限于机器人软件、蜘蛛软件、爬虫软件等任何自动程序、脚本、软件）获取平台的服务、内容、数据（包括但不限于业务数据和用户的个人信息）；</strong
                >
              </p>
              <p>
                <strong
                  >4)<span style="white-space: pre"> </span
                  >规避、移除、更改、阻碍、破坏或以其他方式扰乱我们用于保护我们的产品及/或服务的安全措施；</strong
                >
              </p>
              <p>
                <strong
                  >5)<span style="white-space: pre"> </span
                  >未经我们明确授权，采用收费或免费的方式，在任何公开场合全部或部分展示我们的产品及/或内容（但如您的上述行为不构成侵权的除外）；</strong
                >
              </p>
              <p>
                <strong
                  >6)<span style="white-space: pre"> </span
                  >通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、滥用会员身份、黑色产业、投机等违背我们提供产品及/或服务的初衷的方式）获取、使用我们的产品或服务；</strong
                >
              </p>
              <p>
                <strong
                  >7)<span style="white-space: pre"> </span
                  >伪造标题或以其他方式操控识别资料，使人误认为该内容为我们所传送；</strong
                >
              </p>
              <p>
                <strong
                  >8)<span style="white-space: pre"> </span
                  >将软件病毒、政治宣传、商业招揽（如：广告函件、促销资料）、连锁信、大宗邮件或其他形式的&ldquo;垃圾邮件&rdquo;、侵犯任何人专利、商标、著作权、商业秘密或其他专属权利之内容及/或无权传送之内容（如：内部资料、机密资料）进行上载、发布、发送电子邮件或以其他方式传送；</strong
                >
              </p>
              <p>
                <strong
                  >9)<span style="white-space: pre"> </span
                  >利用恶意软件、网络漏洞或其他非法方式破坏我们的正常运营；窃取、盗用他人的账号及账号下财产等；</strong
                >
              </p>
              <p>
                <strong
                  >10)<span style="white-space: pre"> </span
                  >其他未经我们明示授权许可或违反本协议、法律法规或监管政策、侵犯我们及/或他人合法权益的行为。</strong
                >
              </p>
              <p>
                <strong
                  >4.2
                  您利用我们的产品及服务制作、上传、复制、传送、传播的内容的行为不得违反法律法规及其他规范性文件，不得侵犯我们及或他人的合法权益，且不得包含包括但不限于以下内容：</strong
                >
              </p>
              <p>
                <strong
                  >1)<span style="white-space: pre"> </span
                  >任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人的内容或其他令人反感的包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料；</strong
                >
              </p>
              <p>
                <strong
                  >2)<span style="white-space: pre"> </span
                  >广告信息，包括但不限于广告信息的标题、简介，但我们另行书面许可的除外；</strong
                >
              </p>
              <p>
                <strong
                  >3)<span style="white-space: pre"> </span
                  >任何侵犯我们或他人合法权益、违反法律法规及其他规范性文件（包括但不限于《互联网视听节目服务管理规定》、《网络信息内容生态治理规定》等）及本协议的内容。</strong
                >
              </p>
              <p>
                <strong
                  >4.3
                  您对您利用我们的产品及/或服务制作、上传、发布、传播的符号、文字、图片、音频、视频等内容的行为，独立完整地承担法律责任，包括如造成我们的损失的，应向我们承担赔偿责任。</strong
                >
              </p>
              <p>
                <strong
                  >4.4
                  您不得以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法和不良信息；</strong
                >
              </p>
              <p>
                <strong
                  >4.5
                  您不得冒用、关联机构或社会名人注册账号名称；如因委托方故意或者过失导致的账户冒用而引发的责任和相关损失，由委托方承担。因此给本平台带来的损害，委托人应与实际使用人承担连带责任。</strong
                >
              </p>
              <p>
                <strong
                  >4.6
                  您在本平台上发布需求及相关服务发布信息，不得违反国家法律、法规、行政规章的规定、不得侵犯他人知识产权或其他合法权益的信息、不得违背社会公共利益或公共道德、不得违反本平台的相关规定；</strong
                >
              </p>
              <p>
                <strong
                  >如您发现本平台上存在侵犯其他单位或个人的自身合法权益的内容，请及时与我们取得联系，并提供具有法律效应的初步证明材料，以便本平台作出处理。本平台有权根据实际情况删除相关的内容，并追究相关用户的法律责任。给本平台或任何第三方造成损失的，侵权用户应负全部责任；</strong
                >
              </p>
              <p>
                <strong
                  >4.7
                  您承诺自己在使用本平台实施的所有行为遵守法律、法规、行政规章和本平台的相关规定以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，您将以自己的名义独立承担相应的法律责任；</strong
                >
              </p>
              <p>
                <strong
                  >4.8未经本平台书面允许，用户不得将本平台的任何资料（法律法规除外）以及青春科技及其律师向您发送的资料及文件，包括但不限于法律意见书、合同、诉讼文书等，作商业性利用（包括但不限于以出售）；</strong
                >
              </p>
              <p>
                <strong
                  >4.9
                  您不得使用以下方式登录平台或破坏平台所提供的服务：</strong
                >
              </p>
              <p>
                <strong
                  >1)<span style="white-space: pre"> </span
                  >以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件或其它自动方式访问或登录本平台；</strong
                >
              </p>
              <p>
                <strong
                  >2)<span style="white-space: pre"> </span
                  >通过任何方式对本公司内部结构造成或可能造成不合理或不合比例的重大负荷的行为；</strong
                >
              </p>
              <p>
                <strong
                  >3)<span style="white-space: pre"> </span
                  >通过任何方式干扰或试图干扰网站的正常工作或网站上进行的任何活动。</strong
                >
              </p>
              <p>
                <strong
                  >4.10
                  我们的后台记录有可能作为您违反法律法规、违约、侵权的证据。</strong
                >
              </p>
              <p><br /></p>
              <p>
                <strong
                  >第五条<span style="white-space: pre">、</span
                  >本平台知识产权</strong
                >
              </p>
              <p>
                5.1
                平台所使用的任何相关软件、程序、内容，包括但不限于作品、图片、档案、资料、网站构架、网站版面的安排、网页设计、经由本平台或广告商向用户呈现的广告或资讯，均由本平台或其它权利人依法享有相应的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等，受到相关法律的保护。未经本平台或权利人明示授权，用户保证不修改、出租、出借、出售、散布本平台及本平台所使用的上述任何资料和资源，或根据上述资料和资源制作成任何种类产品；
              </p>
              <p>
                5.2
                本平台授予您不可转移及非专属的使用权，您可以使用本平台，但不得且不得允许任何第三方复制、修改、创作衍生作品、进行还原工程、反向组译，或以其它方式破译或试图破译源代码，或出售、转让软件或对软件进行再授权，或以其它方式移转&ldquo;平台&rdquo;之任何权利。用户同意不以任何方式修改软件，或使用修改后的软件；
              </p>
              <p>5.3 您不得经由非本平台所提供的界面使用本平台。</p>
              <p>
                5.4
                用户在本平台上发布的内容，视作授权其作品使用在本平台上，本平台包括本平台网站以及本平台官方发布的适用于手持终端设备的应用或其他互联网产品；
              </p>
              <p>
                5.5
                在未得到著作权人的授权时，也请不要将他人的作品全部或部分复制发表到本平台，如转载站外信息到本平台请署名和注明出处；
              </p>
              <p>
                5.6
                未经本平台许可，任何人不得盗链本平台下载资源；不得复制或仿造本站或者在非本平台所属服务器上建立镜像；
              </p>
              <p>
                5.7
                本平台的标识（包括但不限于本平台网址，APP，微信公众号、订阅号等）、源代码、及所有页面的版式设计等，版权归本平台所有。未经授权，不得用于除本平台之外的任何站点；
              </p>
              <p>
                5.8
                当本平台用户的言论和行为侵犯了第三方的著作权或其他权利，责任在于用户本人，本平台不承担任何法律责任；
              </p>
              <p>
                5.9
                本平台用户所发言论不代表本平台观点。在本平台发表言论的用户，我们认为用户已经知道并理解这一声明。
              </p>
              <p><br /></p>
              <p>
                <strong
                  >第六条<span style="white-space: pre">、&nbsp;</span
                  >广告、第三方链接
                </strong>
              </p>
              <p>
                6.1
                您知悉并同意，我们在提供我们的产品及/或服务的过程中，在符合法律法规的前提下，保留以各种方式投放商业性广告（包括广告链接）或其他任何类型的商业信息的权利（包括但不限于在我们平台的任何位置上投放广告，在您上传、传播的内容中投放广告）。
              </p>
              <p>
                6.2
                在符合法律法规的前提下，我们有权自主决定广告投放的形式、时长、位置、内容而不受任何第三方的干预。您同意我们可以通过电子邮件、电子短信或其他方式向您发送商品促销或其他相关商业信息，同时我们会为您提供退订方式。
              </p>
              <p>
                6.3
                我们的产品与/或服务可能会提供与其他互联网网站或资源进行链接。我们会依照法律规定对广告商履行相关义务，但您知悉并同意，这些广告商和广告内容非由我们控制，您对广告信息应审慎判断其真实性、可靠性。您通过我们的产品与/或服务与广告商或广告主进行任何形式的行为或产生任何形式的纠纷，由您自行负责与解决，除法律法规规定由广告发布者承担的责任外，我们不承担任何额外责任，但根据需要会依法提供必要的协助。
              </p>
              <p><br /></p>
              <p>
                <strong
                  >第七条<span style="white-space: pre">、</span
                  >个人信息保护</strong
                >
              </p>
              <p>
                <strong
                  >7.1
                  我们非常重视保护隐私和个人信息，亦将用户的个人信息以高度审慎的义务对待和处理。在您使用我们的产品及/或服务的过程中，我们将采用相关技术措施及其他安全措施来保护您的个人信息。</strong
                >
              </p>
              <p><strong>7.2 我们非常注重未成年人的保护</strong></p>
              <p>
                <strong
                  >7.2.1
                  若您为未成年人，应在监护人监护、指导下阅读本协议，并且使用我们的产品及/或服务已经得到监护人的同意；</strong
                >
              </p>
              <p>
                <strong
                  >7.2.2
                  监护人应指导子女上网应该注意的安全问题，防患于未然。若监护人同意未成年人使用我们的产品及/或服务，必须以监护人名义申请消费（如涉及），并对未成年人使用我们的产品及/或服务进行正确引导、监督。未成年人使用我们的产品及/或服务，以及行使和履行本协议项下的权利和义务即视为已获得了监护人的认可；</strong
                >
              </p>
              <p>
                <strong
                  >7.2.3
                  我们提醒未成年人在使用我们的产品与服务时，要善于网上学习，认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。</strong
                >
              </p>
              <p>
                <strong
                  >7.3 更多关于个人信息处理和保护规则，请见
                  &ldquo;个人信息保护政策&rdquo;。</strong
                >
              </p>
              <p><br /></p>
              <p>
                <strong
                  >第八条<span style="white-space: pre">、</span
                  >服务的变更和中断/终止</strong
                >
              </p>
              <p>
                <strong
                  >8.1
                  在法律法规允许的最大限度内，我们有权根据我们的业务发展情况，暂时或永久地变更或终止我们的产品及/或服务（或任何一部分），该等变更或终止对您和任何第三人不承担违约责任，因该等变更或终止导致您未享受已经支付费用的服务的或者导致您合法权益遭受损失的，在您提出申请后，我们将根据确认的实际损失情况给予您适当的退款或补偿。</strong
                >
              </p>
              <p>
                <strong
                  >8.2
                  除前述8.1条所述的情形外，在发生如下情况时，我们有权中断或终止为用户提供的产品及/或服务：</strong
                >
              </p>
              <p>
                <strong
                  >1)<span style="white-space: pre"> </span
                  >您存在或我们经独立判断后认为您存在任何违反国家法律法规或监管政策、违反本协议或有损害我们的声誉、权益的行为的；</strong
                >
              </p>
              <p>
                <strong
                  >2)<span style="white-space: pre"> </span
                  >根据法律法规、监管政策的规定或有权机关的要求；</strong
                >
              </p>
              <p>
                <strong
                  >3)<span style="white-space: pre"> </span
                  >为维护账号与系统安全等紧急情况之需要；</strong
                >
              </p>
              <p>
                <strong
                  >4)<span style="white-space: pre">&nbsp;</span
                  >不可抗力（鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制而造成之暂时关闭、病毒侵袭等影响互联网正常运行之情形）；</strong
                >
              </p>
              <p>
                <strong
                  >5)<span style="white-space: pre">&nbsp;</span
                  >其他无法抗拒的情况。</strong
                >
              </p>
              <p>
                <strong
                  >8.3
                  发生前述8.2条所述之终止的情况时，您与我们均认可如下处理方式：</strong
                >
              </p>
              <p>
                <strong
                  >1)<span style="white-space: pre"> </span
                  >如您在我们平台内有正在进行中的交易，我们届时将视情况进行合理处理；</strong
                >
              </p>
              <p>
                <strong
                  >2)<span style="white-space: pre">&nbsp;</span
                  >如因您违反本协议导致终止，我们有权视情况要求您承担相应的违约责任；</strong
                >
              </p>
              <p>
                <strong
                  >3)
                  除法律法规另有规定或我们另有说明外，我们无需向您和第三人承担任何责任。</strong
                >
              </p>
              <p>&nbsp;</p>
              <p>
                <strong
                  >第九条<span style="white-space: pre">、</span
                  >通知和联系方式</strong
                >
              </p>
              <p>
                <strong
                  >9.1
                  本协议项下我们对于用户所有的通知均可通过网页公告、站内信、电子邮件、手机短信或常规的信件传送等方式进行，该等通知于发送之日视为已送达您。</strong
                >
              </p>
              <p>
                <strong
                  >9.2
                  如您对本协议或使用我们的产品与/或服务相关的事宜有任何问题（包括问题咨询、投诉等），我们专门为您提供了多种反馈通道，希望为您提供满意的解决方案：</strong
                >
              </p>
              <p>
                <strong
                  >9.3
                  在线客服/其他在线意见反馈通道：您可与我们平台上产品功能页面的在线客服联系或者在线提交意见反馈；</strong
                >
              </p>
              <p>
                <strong
                  >人工客服通道：您可以拨打我们的客服电话与我们联系；</strong
                >
              </p>
              <p><strong>其他方式：我们提供的其他反馈通道。</strong></p>
              <p><strong>我们会在收到您的反馈后尽快向您答复。</strong></p>
              <p><br /></p>
              <p>
                <strong
                  >第十条<span style="white-space: pre">&nbsp;、</span
                  >协议文本</strong
                >
              </p>
              <p>
                10.1【协议的接受】使用我们的产品或服务（&ldquo;使用&rdquo;行为指包括但不限于下载、安装、启动、浏览、注册、登录等行为中的一种或多种，下同）即表示您已确认理解并同意本协议，且您自愿受本协议的条款约束。如果您不接受本协议的任何条款，您应立即停止使用我们的产品及/或服务。
              </p>
              <p>
                10.2【协议的更新】青春科技有权随时在本网站公告经过更新过的本协议，并注明更新条款。经修订的条款一经在平台公布后，立即自动生效。如您不同意相关变更，应当立即停止使用本平台，继续使用的，视为同意。
              </p>
              <p>
                10.3【协议的统一性】本协议内容包括协议正文及所有本平台已经发布的各类规则。所有规则为本协议不可分割的一部分，与本协议正文具有同等法律效力。一旦您继续使用本平台，则表示您已接受并自愿遵守经修订后的条款。
              </p>
              <p>
                用户与青春科技就同一事项建立其他服务关系的，若本协议于线下服务合同及约定内容冲突的，应当以线下服务合同或委托代理合同为准。
              </p>
              <p>
                10.4【协议的种类】需要特别提醒您的是：由于我们的产品及/或服务不同，本协议为统一适用的一般性用户服务条款。针对我们的某些特定产品及/或服务，我们还将制定特定用户服务协议，以便更具体地向您阐明该等产品及/或服务的服务内容、服务规则等内容，您应在充分阅读并同意特定用户服务协议的全部内容后再使用该特定产品/服务。如您在我们平台上使用第三方提供的产品及/或服务时，除遵守本协议约定外，还应遵守第三方的服务条款。
              </p>
              <p><br /></p>
              <p>
                <strong
                  >第十一条<span style="white-space: pre">、&nbsp;</span
                  >纠纷解决</strong
                >
              </p>
              <p>
                11.1
                本协议的有效性、履行和与本协议及效力有关的所有事宜，应当由中华人民共和国法律管辖，任何争议仅适用中华人民共和国法律；
              </p>
              <p>
                11.2
                凡因履行本协议发生的纠纷以及在本平台网上交易产生的所有纠纷，各方可协商解决；若协商不成的，各方一致同意提交南京市鼓楼区人民法院诉讼解决。
              </p>
              <p><br /></p>
              <p>
                <strong
                  >第十二条<span style="white-space: pre">、</span>其他</strong
                >
              </p>
              <p>12.1本协议未约定事项，您可以通过平台官方客服与本公司沟通。</p>
              <p><br /></p>
              <p><br /></p>
              <h3 class="text-center">免责声明</h3>
              <br />
              <p>
                1.<span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span
                >&ldquo;先识&rdquo;尽调辅助审查服务是基于贵司以下承诺作出：贵司向本公司提供的书面材料、口头陈述，是真实、合法、有效的，无任何隐瞒或重大遗漏；提供的材料为副本的，与正本一致；提供的材料为复印件的，与原件一致。截止到书面（电子）报告出具之日，各项文件在提供给本所审查之后均没有发生任何修改、修订或其他变动。本公司不会对所提供文件及文件记载事实作进一步查证，本所不对文件的真实性、准确性负责。本单位出具的报告对上述文件中某些数据、事实或结论的引述，并不意味着对这些数据、事实或结论的准确性、真实性做出任何明示或默示的保证。
              </p>
              <p>
                2.<span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span
                >本公司出具报告系根据贵司的陈述，上传资料，及我们对该等陈述及法律法规、行业准则的理解作出，可根据贵司所述情况、上传资料和中国法律、行业准则的不时变更而不时进行补充、删节和修改。
              </p>
              <p>
                3.<span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span
                >本公司出具的报告仅供贵司参考使用，未征得本公司书面许可或确认，不得向其他第三方披露、透露，并不得擅自引用或转述或据以向本公司追究任何责任。
              </p>
              <p>
                4.<span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span
                >本报告所分析的数据最终被第三方作出何种判定并非本公司所能掌控。对此，特提示贵司对本报告持审慎参考的态度，不断改进、完善所上传的数据的真实性与准确性。贵公司并不能仅依靠此报告而取代行使独立判断，本公司不对因使用此报告的材料而引致的损失而负上任何责任，除非法律法规有明确规定。
              </p>
              <p>
                5.<span style="white-space: pre">&nbsp; &nbsp;&nbsp;</span
                >出于反作弊、国家法规政策更新等原因，本公司将持续对分析规则进行完善和更新并无须告知客户，因此对同样数据在不同时间分析可能会产生不同结果。
              </p>
              <p><br /></p>
              <p><br /></p>
              <h3 class="text-center">个人信息保护政策</h3>
              <br />
              <p>
                南京青春信息科技有限公司（包括其子公司及附属公司，以下简称为&ldquo;青春科技&rdquo;或者&ldquo;我们&rdquo;）在提供产品及服务的过程中，可能会收集和处理您的个人信息。本隐私政策（又称&ldquo;个人信息保护政策&rdquo;）旨在解释我们会如何收集和处理该等个人信息，包括涵盖有本隐私政策的网站、应用程序、客服热线或其他与我们产品和服务相关的平台（如网站）中收集的个人信息，以及在您使用本网站过程中收集到的其他信息。
              </p>
              <p>
                本隐私政策不适用于：i)通过线下收集的个人信息；ii）通过不涵盖本隐私政策的网站中收集的个人信息；iii)
                非中华人民共和国公民的个人信息；iv)
                第三方平台收集的个人信息。您对于本网站的使用，将适用并受限于本隐私政策及使用条款。您在此同意本隐私政策及使用条款。
              </p>
              <p>请在使用本网站或向我们提交您的个人信息前阅读本政策。</p>
              <p>
                1.本隐私政策中的&ldquo;个人信息&rdquo;是指可识别、或可用于识别个人身份或者个人行为的信息（无论是电子形式还是其他形式），并被提交至本网站和/或由本网站收集的、由本网站以可获取的形式保存的信息。个人信息包括基本个人信息（例如：姓名、生日、性别、邮政地址、电话号码、电子邮件地址）、个人可识别信息（例如：身份证号码、护照号码）、网络可识别信息（例如：IP地址、地理位置信息、账户名和密码）、健康信息（例如：疾病症状、医疗记录、医疗史、临床数据及与个人健康状态相关的信息）、个人信用记录（例如：银行账户信息）、在线活动记录（例如：网站浏览及点击记录）以及个人位置信息。
              </p>
              <p>2.本网站通过两种方式收集信息：</p>
              <p>
                &middot;
                收集您提交的个人信息：青春科技会收集您在网站的数据字段中输入的个人信息。例如，您可能会提交姓名、电话号码、邮政地址、电子邮件地址、地理位置和（或）其它信息，以便接收各种主题的信息、报名注册活动、联系青春科技客服，或者回复青春科技的调查。为了保护个人隐私，您不应提供除青春科技特别要求之外的任何其它信息。
              </p>
              <p>
                &middot;
                被动收集无法用于识别的信息：在您未主动提交时，青春科技网站会收集有关您对本网站访问的信息。无法用于识别的信息可由青春科技及广告合作商通过多种技术收集，例如cookie，互联网标签、Web
                beacon和日志文件。您的 Internet
                浏览器会自动将这些无法用于识别的信息（例如刚访问过的网站 URL
                和计算机运行的浏览器版本）传送给青春科技或广告合作商。借助被动信息收集技术，青春科技可以优化服务、根据消费者偏好定制平台、编辑统计数据并分析趋势，在此基础上提供更好的服务、打造贴近用户的平台，以及通过其他方式进行管理和改进，从而使您更方便地使用本网站。离开了被动信息收集技术，本网站的某些功能可能会无法运行。除非辅以其它身份识别信息，否则以这些技术收集的信息无法对您进行识别。
              </p>
              <p>3.青春科技会在什么情况下、又会基于怎样的目的收集个人信息?</p>
              <p>
                青春科技可能基于以下理由收集和使用您通过本网站提交的个人信息，包括：核实您的身份、履行您作为一方的协议、回复您的问题或投诉、为您提供更高效的客服服务、帮助我们提升市场营销及研究能力，以及确保我们可以符合法律的义务和规定。
              </p>
              <p>
                当您在本网站的表格或数据字段中输入个人信息后，青春科技将采用特定的识别技术使平台&ldquo;记住&rdquo;您的偏好，例如您经常访问本网站的哪个部分，以及您的用户
                ID（如果您选择此项）。我们可能也会将您的个人信息用于其他商业目的，例如，让您有机会接收青春科技产品或服务通知、邀请您参加产品调查，或者通知您特别促销活动。
              </p>
              <p>4.青春科技会整合个人信息吗?</p>
              <p>
                青春科技会对使用本网站的客户个人信息进行整合。此外，我们可能会以不可识别的方式整合该信息（如汇总信息或匿名化），以帮助我们更好地设计本网站和青春科技产品及服务，推动研究活动的开展，并为其它业务功能提供便利。
              </p>
              <p>5.青春科技与第三方共享个人信息吗?</p>
              <p>
                一般而言，青春科技不会将您的个人信息转交第三方（除非在）。如果青春科技出售某个产品线或业务部门，您的个人信息可能被转移给买方，以便买方继续为您提供优惠信息。青春科技有可能向协助青春科技开展商业活动的个人和组织透露您的个人信息。依据青春科技的商业实践，青春科技会要求该等个人和组织对您的个人信息保密，并且只将您的个人信息用于为青春科技行使职能。此外，青春科技保留因回应政府机构的官方要求、回应国家安全问题、或根据法律要求而透露您个人信息的权利。
              </p>
              <p>6.青春科技会将个人信息进行国际传输吗?</p>
              <p>
                我们将遵循中国法律法规对于数据跨境传输的规定。我们理解您在阅读并同意本隐私政策后，您同意我们将您的个人信息与青春科技集团的其他成员公司或青春科技授权的第三方进行分享和/或传输。
              </p>
              <p>7.青春科技如何确保证个人信息的安全?</p>
              <p>
                我们已经采取相关的技术措施和必要手段，以保护您提供的个人信息，并防止该等信息不会遭受未经授权的访问、披露、使用、修改、损毁和遗失。我们对公司员工进行培训，教育他们重视隐私、了解如何安全恰当地处理和管理客户数据。
              </p>
              <p>
                青春科技依据其商业实践会确保所有收集个人信息的本网站的安全性。但是，青春科技无法保证通过Internet传输信息时的保密性。我们强烈要求您在通过Internet
                传输个人信息（尤其是涉及您的健康的信息）时保持高度警惕。尽管青春科技对您的信息采取了一定技术保护，青春科技不能确保未经授权的第三方不会访问您的个人信息；因此，请您在向本网站提交个人信息时，务必权衡利弊和风险、谨慎行事。此外，当您从受此隐私政策管辖的本网站链接到非青春科技控制的平台时，本网站将显示一条警告信息；您应当首先查看此类第三方平台的隐私政策，然后再提交个人信息。
              </p>
              <p>8.对于青春科技如何收集和使用我的个人信息，我有什么选择?</p>
              <p>
                您可以选择拒绝在本网站的表格和数据字段中输入任何个人信息，从而限制青春科技对您个人信息的收集数量和类型。我们某些在线服务仅当您提供了恰当的个人信息后方可使用。
              </p>
              <p>
                根据您的要求，我们会让您访问我们所保存的您的个人信息，除非在特定情形下我们有权保留该等信息。在您的协助下，青春科技会确保您的个人信息的准确性和及时性。您可以联系青春科技要求删除、修改或者更正您的个人信息。
              </p>
              <p>
                在我们基于您的同意收集和处理您的个人信息时，您有权撤回您的同意。然而，请知悉仅当您提供了恰当的个人信息后方可使用我们的部分产品或服务。如果您撤销了同意，我们将无法继续向您提供该等商品或服务，并会停止处理您的相关信息。您对于同意的撤销将不具有溯及力，不会影响我们已经对您信息进行的处理。
              </p>
              <p>
                为了确保对于您个人信息的恰当保护，我们可能需要您以书面方式提交以上要求，或通过其他方式核实您的身份。如果您的要求不合理或是重复的，我们将无法处理该等要求。在以下情形下，我们将无法处理您的要求：
              </p>
              <p>&middot; 您的要求与国家安全和国防安全直接相关；</p>
              <p>&middot; 您的要求与公共安全、公共健康和公共利益直接相关；</p>
              <p>&middot; 您的要求与刑事调查、起诉、审判和执行直接相关；</p>
              <p>
                &middot;
                足够的证据表明，您基于恶意或在滥用权利的情况下，提出该等要求；
              </p>
              <p>
                &middot;
                如果对您的要求进行回应，将会严重损害您或其他人或其他组织的合法权益；
              </p>
              <p>&middot; 您的要求与我们商业秘密相关。</p>
              <p>9.青春科技如何保护儿童的隐私?</p>
              <p>
                青春科技不会在本网站上有意收集或使用儿童的任何个人信息（我们将&ldquo;儿童&rdquo;定义为
                14周岁以下的未成年人）。
              </p>
              <p>10.我如何联系青春科技?</p>
              <p>
                如果您对所提供个人信息的使用、访问、修改或删除有任何疑问，或希望以后不再接受青春科技业务或项目的特定信息，请单击您所访问本网站帮助中心提交，或发送电子邮件至：jugends@163.com
              </p>
              <p>或者，您也可以将信件寄至以下地址：</p>
              <p>公司名称：南京青春信息科技有限公司</p>
              <p>地址：江苏省南京市鼓楼区汉口路22号</p>
              <p>电子邮件：jugends@163.com</p>
              <p>
                您在所有与青春科技的联系中，请提供用于注册的电子邮件地址/移动电话信息（如果适用）、您提供个人信息的平台地址，并且详细解释您的请求。如果您希望删除或更正您的个人信息，并愿以电子邮件联系我们，请在电子邮件的主题行中注明&quot;删除请求&quot;或&quot;更正请求&quot;等字样（如适用）。我们将尽力及时答复所有合理的请求。
              </p>
              <p>11.隐私政策更新?</p>
              <p>
                青春科技保留不经事先通知您更新此隐私政策的权利，以反映技术进步、法律法规的变动以及优秀商业实践。如果青春科技更改了关于隐私的实践操作，我们将在更新版本的隐私政策中体现这些更改。更新的隐私政策的生效日期见本节规定。
              </p>
              <p>此隐私政策于2021年1月1日更新并生效。</p>
              <p><br /></p>
              <p><br /></p>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              同意并继续使用
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "user-agreement",
};
</script>

<style scoped>
.agreement-slide-enter-active {
  transition: all 0.2s ease-out;
}

.agreement-slide-leave-active {
  transition: all 0.2s ease-out;
}

.agreement-slide-enter-from,
.agreement-slide-leave-to {
  transform: translateY(80vh);
}
</style>
