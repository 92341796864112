import Cookies from "js-cookie";
const ID_TOKEN_KEY = "X-Token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return Cookies.get(ID_TOKEN_KEY) ?? null;
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string, expire: Date): void => {
  if (token && token !== "undefined") {
    Cookies.set(ID_TOKEN_KEY, token, {
      expires: expire,
    });
  }
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  Cookies.remove(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
