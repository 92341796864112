import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations, Roles } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface Account {
  balance: number;
  frozen: number;
  invoiceAble: number;
  refundAble: number;
}

export interface User {
  id: string;
  username: string;
  phone: string;
  avatar: string;
  roles: Array<Roles>;
  account: Account;
}

export interface LoginRecord {
  _id: string;
  time: number;
  ip: string;
  location: string;
  ua: string;
  type: string;
}

export interface UserAuthInfo {
  user: User;
  loginRecords: Array<LoginRecord>;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  user = {} as User;
  loginRecords = [];

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User | null {
    return Object.keys(this.user).length ? this.user : null;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return !!JwtService.getToken();
  }

  get userLoginRecords(): Array<LoginRecord> {
    return this.loginRecords;
  }

  @Mutation
  [Mutations.SET_AUTH](payload) {
    JwtService.saveToken(payload.token, new Date(payload.expire));
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_LOGIN_RECORDS](records) {
    this.loginRecords = records;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.user = {} as User;
    JwtService.destroyToken();
  }

  @Action({ rawError: true })
  [Actions.LOGIN](credentials) {
    return ApiService.post("user/auth", credentials).then(({ data }) => {
      this.context.commit(Mutations.SET_USER, { ...data.user });
      this.context.commit(Mutations.SET_AUTH, {
        token: data.token,
        expire: data.expire,
      });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_PASSWORD](payload) {
    return ApiService.put("user/password", payload);
  }

  @Action({ rawError: true })
  [Actions.UPDATE_EMAIL](payload) {
    return ApiService.put("user/email", payload).then(({ data }) => {
      this.context.commit(Mutations.SET_USER, data.user);
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action({ rawError: true })
  [Actions.DEACTIVATE]() {
    return ApiService.get("user/deactivate");
  }

  @Action
  [Actions.GET_USER]() {
    return ApiService.get("user/info").then(({ data }) => {
      this.context.commit(Mutations.SET_USER, data);
    });
  }

  @Action
  [Actions.GET_LOGIN_RECORDS]() {
    this.context.commit(Mutations.SET_LOGIN_RECORDS, []);
    return ApiService.get("user/loginrecord").then(({ data }) => {
      this.context.commit(Mutations.SET_LOGIN_RECORDS, data);
    });
  }

  @Action({ rawError: true })
  [Actions.REGISTER](credentials) {
    return ApiService.post("user/register", credentials);
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (!JwtService.getToken()) {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
