import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/auth/LoginCard.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/auth/RegisterCard.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dd/finance-analysis",
    component: () => import("@/layout/Layout.vue"),
    meta: { roles: ["user", "admin"] },
    children: [
      {
        path: "/dd/finance-analysis",
        name: "finance-analysis",
        component: () => import("@/views/finance-analysis/FinanceAnalysis.vue"),
      },
      {
        path: "/dd/ip-analysis",
        name: "ip-analysis",
        component: () => import("@/views/ip-analysis/IPAnalysis.vue"),
      },
      {
        path: "/finance",
        name: "finance",
        redirect: "/finance/change",
        component: () => import("@/views/finance/FinanceCenter.vue"),
        children: [
          {
            path: "orders",
            name: "my-orders",
            component: () => import("@/views/finance/OrderTable.vue"),
          },
          {
            path: "pay",
            name: "my-pay",
            component: () => import("@/views/finance/PurchaseTable.vue"),
          },
          {
            path: "change",
            name: "my-change",
            component: () => import("@/views/finance/ChangeTable.vue"),
          },
          {
            path: "invoice",
            name: "my-invoice",
            component: () => import("@/views/finance/InvoiceTable.vue"),
          },
          // {
          //   path: "refund",
          //   name: "my-refund",
          //   component: () => import("@/views/finance/RefundTable.vue"),
          // },
        ],
      },
      {
        path: "/account",
        name: "account",
        redirect: "/account/settings",
        component: () => import("@/views/account/Account.vue"),
        children: [
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/account/AccountSettings.vue"),
          },
          {
            path: "logs",
            name: "login-logs",
            component: () => import("@/views/account/LoginLogs.vue"),
          },
          {
            path: "notifications",
            name: "notifications",
            component: () => import("@/views/account/NotificationList.vue"),
          },
        ],
      },
      {
        path: "/help",
        name: "help",
        redirect: "/help/faq",
        component: () => import("@/views/help/HelpCenter.vue"),
        children: [
          {
            path: "faq",
            name: "faq",
            component: () => import("@/views/help/FrequentlyAskedQuestion.vue"),
          },
          {
            path: "ticket",
            name: "ticket",
            component: () => import("@/views/help/TicketList.vue"),
          },
          {
            path: "ticket/view/:ticketId",
            name: "ticketDetail",
            component: () => import("@/views/help/TicketDetail.vue"),
          },
        ],
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/auth/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const needPermission = (routes) => {
  return routes.some((route) => {
    return !!route.meta?.roles?.length;
  });
};

const hasPermission = function (roles, routes) {
  let hasRole = false;
  routes.forEach((route) => {
    hasRole = roles.some((role) => route.meta?.roles?.includes(role));
  });
  return hasRole;
};

router.beforeEach((to, from) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH);

  if (needPermission(to.matched) && !store.getters.isUserAuthenticated) {
    Swal.fire({
      text: "登录已过期，请重新登录",
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: "重新登录",
      customClass: {
        confirmButton: "btn fw-bold btn-light-danger",
      },
    }).then(function () {
      router.push({ name: "sign-in" });
    });
  } else if (
    store.getters.isUserAuthenticated &&
    (to.name == "sign-in" || to.name == "sign-up")
  ) {
    router.push({ name: "finance-analysis" });
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
