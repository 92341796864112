import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface FAReport {
  created_ts: number;
  report_id: string;
  name: string;
  rate: string;
}

export interface IPReport {
  created_ts: number;
  report_id: string;
}

@Module
export default class ReportModule extends VuexModule {
  FAReportList: Array<FAReport> = [];
  IPReportList: Array<IPReport> = [];

  get FAReports() {
    return this.FAReportList;
  }

  get IPReports() {
    return this.IPReportList;
  }

  @Mutation
  [Mutations.SET_IP_REPORTS](reports) {
    this.IPReportList = reports;
  }

  @Mutation
  [Mutations.SET_FA_REPORTS](reports) {
    this.FAReportList = reports;
  }

  @Action
  [Actions.GET_IP_REPORTS]() {
    return ApiService.get("/ip-analysis/report").then(({ data }) => {
      this.context.commit(Mutations.SET_IP_REPORTS, data);
    });
  }

  @Action
  [Actions.GET_FA_REPORTS]() {
    return ApiService.get("/finance-analysis/report").then(({ data }) => {
      this.context.commit(Mutations.SET_FA_REPORTS, data);
    });
  }

  @Action
  [Actions.GET_IP_REPORT_DETAIL](id) {
    return ApiService.get("/ip-analysis/report", id);
  }

  @Action
  [Actions.GET_FA_REPORT_DETAIL](id) {
    return ApiService.get("/finance-analysis/report", id);
  }

  @Action({ rawError: true })
  [Actions.IP_ANALYSIS](payload) {
    return ApiService.post("/college/ip-analysis", payload).then(({ data }) => {
      this.context.commit(Mutations.ADD_ORDER, data.order);
    });
  }

  @Action({ rawError: true })
  [Actions.FINANCE_ANALYSIS](payload) {
    return ApiService.post("/college/finance-analysis", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.ADD_ORDER, data.order);
      })
      .catch((e) => {
        return e.response?.data?.message || "服务器繁忙";
      });
  }
}
