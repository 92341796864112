import { App } from "vue";
import store from "@/store";
import router from "@/router/clean";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { Mutations } from "@/store/enums/StoreEnums";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_API_BASE;
    ApiService.setHeader();
    ApiService.setInterceptors();
  }

  private static setInterceptors() {
    // 设置动态Header
    ApiService.vueInstance.axios.interceptors.request.use(function (config) {
      config.headers["X-Ca-Timestamp"] = Date.now();
      config.headers["X-Ca-Nonce"] = Math.random().toString(36);
      const token = JwtService.getToken();
      if (token) {
        config.headers["X-Token"] = token;
      }
      return config;
    });

    // 截获401错误
    ApiService.vueInstance.axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          store.commit(Mutations.PURGE_AUTH);
          Swal.fire({
            text: "登录已过期，请重新登录",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "重新登录",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(function () {
            router.push({ name: "sign-in" });
          });
        } else {
          if (!error.response.data.message) {
            if (!error.response.data) {
              error.response.data = {};
            }
            error.response.data.message = "服务器繁忙";
          }
          return Promise.reject(error);
        }
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `APPCODE ${process.env.VUE_APP_API_CODE}`;
    axios.defaults.headers.common["X-Ca-Stage"] = process.env.VUE_APP_API_STAGE;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }

  public static download(resource: { name: string; url: string }) {
    fetch(resource.url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${resource.name}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}

export default ApiService;
