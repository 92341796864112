enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  GET_USER = "getUser",
  GET_LOGIN_RECORDS = "getLoginRecords",
  UPDATE_EMAIL = "updateEmail",
  UPDATE_PASSWORD = "updatePassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  DEACTIVATE = "deactivate",

  //Account actions
  INVOKE_ALIPAY = "invokeAlipay",
  GET_ACCOUNT_RECORDS = "getAccountRecords",
  GET_ORDERS = "getOrders",

  //Report actions
  FINANCE_ANALYSIS = "financeAnalysis",
  IP_ANALYSIS = "ipAnalysis",
  GET_FA_REPORTS = "getFAReports",
  GET_FA_REPORT_DETAIL = "getFAReportDetail",
  GET_IP_REPORTS = "getIPReports",
  GET_IP_REPORT_DETAIL = "getIPReportDetail",

  //Ticket actions
  SUBMIT_TICKET = "submitTicket",
  REPLY_TICKET = "replyTicket",
  GET_TICKET_LIST = "getTicketList",
  GET_TICKET_DETAIL = "getTicketDetail",

  //Message actions
  READ_MESSAGE = "readMessage",
  CLEAR_UNREAD = "clearUnreadMessages",
  GET_MESSAGES = "getMessages",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_LOGIN_RECORDS = "setLoginRecords",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  // Account mutations
  SET_ACCOUNT_RECORDS = "setAccountRecords",
  SET_ORDERS = "setOrders",
  ADD_ORDER = "addOrder",
  UPDATE_ORDER = "updateOrder",

  // Report mutations
  SET_FA_REPORTS = "setFAReports",
  SET_IP_REPORTS = "setIPReports",

  // Ticket mutations
  SET_TICKETS = "setTickets",
  SET_CURRENT_TICKET = "setCurrentTicket",
  ADD_TICKET = "addTicket",
  ADD_REPLY = "addReply",

  // Message mutations
  ADD_MESSAGES = "addMessages",
  READ_MESSAGE = "readLocalMessage",
  CLEAR_UNREAD = "clearLocalUnreadMessages",
  CLEAR_ALL = "clearAllLocalMessages",
}

enum Roles {
  USER = "user",
  ADMIN = "admin",
}

export { Actions, Mutations, Roles };
