import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface Ticket {
  id: number;
  subject: string;
  tags: Array<string>;
  status: number;
  created_at: string;
  conversations?: Array<any>;
}

@Module
export default class MessageModule extends VuexModule {
  unreadMessagesArray: Array<any> = [];
  readMessagesArray: Array<any> = [];

  get unreadMessageList() {
    return this.unreadMessagesArray;
  }

  get allMessageList() {
    return this.unreadMessagesArray.concat(this.readMessagesArray);
  }

  @Mutation
  [Mutations.ADD_MESSAGES](messages) {
    messages = messages.sort((a, b) => {
      return a.created_ts > b.created_ts;
    });
    messages.forEach((m) => {
      if (!m.read_ts) {
        this.unreadMessagesArray.unshift(m);
      } else {
        this.readMessagesArray.unshift(m);
      }
    });
  }

  @Mutation
  [Mutations.READ_MESSAGE](messageId) {
    const index = this.unreadMessagesArray.findIndex((m) => {
      return m.id === messageId;
    });
    if (index > -1) {
      this.unreadMessagesArray[index].read_ts = new Date();
      this.readMessagesArray.unshift(this.unreadMessagesArray[index]);
      this.unreadMessagesArray.splice(index, 1);
    }
  }

  @Mutation
  [Mutations.CLEAR_UNREAD]() {
    for (let i = 0; i < this.unreadMessagesArray.length; i++) {
      this.unreadMessagesArray[i].read_ts = new Date();
      this.readMessagesArray.unshift(this.unreadMessagesArray[i]);
      this.unreadMessagesArray.splice(i, 1);
      i--;
    }
  }

  @Mutation
  [Mutations.CLEAR_ALL]() {
    this.unreadMessagesArray = [];
    this.readMessagesArray = [];
  }

  @Action
  [Actions.READ_MESSAGE](messageId) {
    this.context.commit(Mutations.READ_MESSAGE, messageId);
    return ApiService.delete(`/message/read/${messageId}`);
  }

  @Action
  [Actions.CLEAR_UNREAD]() {
    this.context.commit(Mutations.CLEAR_UNREAD);
    return ApiService.delete("/message/read");
  }

  @Action
  [Actions.GET_MESSAGES]() {
    this.context.commit(Mutations.CLEAR_ALL);
    return ApiService.get("/message/list").then(({ data }) => {
      this.context.commit(Mutations.ADD_MESSAGES, data);
    });
  }
}
