import { Form, Field, ErrorMessage, configure, defineRule } from "vee-validate";

export function initVeeValidate(app) {
  defineRule("required", (value) => {
    if (!value || !value.trim().length) {
      return "必填项";
    }
    return true;
  });
  defineRule("username", (value) => {
    if (!value || value.trim().length < 4 || value.trim().length > 20) {
      return "用户名应为4-20个字符";
    }
    return true;
  });
  defineRule("password", (value) => {
    if (!value || !/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/.test(value)) {
      return "密码应至少8位，并包含大写字母、小写字母及数字";
    }
    return true;
  });
  defineRule("confirmed", (value, [other]) => {
    if (value !== other) {
      return "两次输入的密码不一致";
    }
    return true;
  });
  defineRule("agreed", (value) => {
    if (!value) {
      return "请先阅读并勾选同意《使用协议》";
    }
    return true;
  });

  // Updating default vee-validate configuration
  configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true,
  });

  app.component("VeeForm", Form);
  app.component("VeeField", Field);
  app.component("VeeErrorMessage", ErrorMessage);
}
