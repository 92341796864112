import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export const TicketStatusDictionary = {
  2: "待处理",
  3: "待客户确认",
  4: "已解决",
  5: "已完结",
};

export interface Ticket {
  id: number;
  subject: string;
  tags: Array<string>;
  status: number;
  created_at: string;
  conversations?: Array<any>;
}

@Module
export default class TicketModule extends VuexModule {
  ticketList: Array<Ticket> = [];
  ticketDetail: Ticket | null = null;

  get tickets() {
    return this.ticketList;
  }

  get currentTicket() {
    return this.ticketDetail;
  }

  @Mutation
  [Mutations.SET_CURRENT_TICKET](ticket) {
    this.ticketDetail = ticket;
  }

  @Mutation
  [Mutations.SET_TICKETS](tickets) {
    this.ticketList = tickets;
  }

  @Mutation
  [Mutations.ADD_TICKET](ticket) {
    this.ticketList.unshift(ticket);
  }

  @Mutation
  [Mutations.ADD_REPLY](reply) {
    if (this.ticketDetail?.conversations) {
      this.ticketDetail.conversations.push(reply);
      this.ticketDetail.status = 2;
    }
  }

  @Action({ rawError: true })
  [Actions.SUBMIT_TICKET](payload) {
    return ApiService.post("/ticket/create", payload).then(({ data }) => {
      this.context.commit(Mutations.ADD_TICKET, data);
    });
  }

  @Action({ rawError: true })
  [Actions.REPLY_TICKET](payload) {
    return ApiService.post("/ticket/reply", payload).then(({ data }) => {
      this.context.commit(Mutations.ADD_REPLY, data);
    });
  }

  @Action
  [Actions.GET_TICKET_LIST]() {
    this.context.commit(Mutations.SET_TICKETS, []);
    return ApiService.get("/ticket/getlist").then(({ data }) => {
      this.context.commit(Mutations.SET_TICKETS, data);
    });
  }

  @Action
  [Actions.GET_TICKET_DETAIL](id) {
    this.context.commit(Mutations.SET_CURRENT_TICKET, null);
    return ApiService.query("/ticket/getdetail", {
      params: { ticket_id: id },
    }).then(({ data }) => {
      this.context.commit(Mutations.SET_CURRENT_TICKET, data);
    });
  }
}
